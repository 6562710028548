/** @jsxImportSource theme-ui */
import { Container, Heading, Box, Flex } from 'theme-ui';
import CreateIncentive from '@/containers/incentives/CreateIncentive';

const IncentivePage = () => {
    return (
        <Container>
            <Heading sx={{ textTransform: 'capitalize' }} as="h2">
                Create Incentive
            </Heading>
            <CreateIncentive />
        </Container>
    );
};

export default IncentivePage;
