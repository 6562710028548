import keycloak from '@/keycloak';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TypedStartListening } from '@reduxjs/toolkit';
import { definitions, operations } from '@/apitypes';
import {
    CommentType,
    CompanyComment,
    CreateCompanyComment,
    SyncMailchimpPutQuery,
    PostIncentive,
    PostIncentiveResponse,
    GetIncentivesResponse,
    GetIncentivesQuery,
    GetLicenseTypesQuery,
    GetLicenseTypesResponse,
} from '@/types/types';
import { createListenerMiddleware } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { ApplicationDispatch, ApplicationState } from '@/store';
import { defaultSyncMailchimpOptions } from './mailchimp';

export const apiServiceListener = createListenerMiddleware();
export const apiServiceStartListening = apiServiceListener.startListening as TypedStartListening<
    ApplicationState,
    ApplicationDispatch
>;

export const apiService = createApi({
    reducerPath: 'bmApi',
    tagTypes: ['company', 'companyComments', 'companyApiKey', 'incentives'],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY,
            'x-language': 'sv',
        },
        prepareHeaders(headers, api) {
            const bearerToken = keycloak?.token;
            const sessionId = keycloak?.sessionId;

            if (bearerToken && sessionId) {
                headers.set('Authorization', `Bearer ${bearerToken}`);
                headers.set('X-Ss-Id', `${sessionId}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => {
        return {
            getCompany: builder.query<
                operations['CompanyQuery_Get']['responses']['200']['schema']['Results'][0],
                definitions['CompanyQuery']
            >({
                transformResponse: (
                    response: operations['CompanyQuery_Get']['responses']['200']['schema']
                ) => {
                    return response.Results[0];
                },
                query: (params) => ({
                    url: '/superadmin/companies',
                    method: 'get',
                    params: {
                        ...params,
                        Take: 1,
                    },
                }),
                providesTags: ['company'],
            }),
            getCompanyBookings: builder.query<
                operations['SuperAdminBookingQuerybookings_Get']['responses']['200']['schema'],
                operations['SuperAdminBookingQuerybookings_Get']['parameters']['query']
            >({
                query: (params) => ({
                    url: '/superadmin/bookings',
                    method: 'get',
                    params: {
                        CompanyBookings: true,
                        IncludeServiceInformation: true,
                        Take: 50,
                        ...params,
                    },
                }),
                providesTags: ['company'],
            }),
            getCompanyAdministrators: builder.query<
                operations['SuperAdminCompanyUserQueryadministrators_Get']['responses']['200']['schema'],
                operations['SuperAdminCompanyUserQueryadministrators_Get']['parameters']['query']
            >({
                query: (params) => ({
                    url: '/superadmin/administrators',
                    method: 'get',
                    params: {
                        Take: 50,
                        ...params,
                    },
                }),
                providesTags: ['company'],
            }),
            getCompanyLicenses: builder.query<
                operations['SuperAdminCompanyLicenseQuerylicensescompany_Get']['responses']['200']['schema']['Results'],
                operations['SuperAdminCompanyLicenseQuerylicensescompany_Get']['parameters']['query']
            >({
                query: (params) => ({
                    url: '/superadmin/licenses/company',
                    method: 'get',
                    params: {
                        Take: 50,
                        ...params,
                    },
                }),
            }),
            getLicenseTypes: builder.query<GetLicenseTypesResponse, GetLicenseTypesQuery>({
                query: (params) => ({
                    url: '/licenses/types/',
                    method: 'get',
                    params: {
                        ...params,
                    },
                }),
            }),
            getCompanyInvoices: builder.query<
                operations['SuperAdminCompanyInvoiceQuerybillingcompanyinvoices_Get']['responses']['200']['schema'],
                operations['SuperAdminCompanyInvoiceQuerybillingcompanyinvoices_Get']['parameters']['query']
            >({
                query: (params) => ({
                    url: '/superadmin/billing/company/invoices',
                    method: 'get',
                    params: {
                        Take: 50,
                        ...params,
                    },
                }),
            }),
            deleteCompany: builder.mutation<
                any,
                { companyId: string; gotApprovedByAdmin?: boolean; force?: boolean }
            >({
                query: ({ companyId, gotApprovedByAdmin, force = false }) => ({
                    url: `/superadmin/company/${companyId}`,
                    method: 'delete',
                    params: {
                        CompanyId: companyId,
                        GotApprovedByAdmin: !!gotApprovedByAdmin,
                        Force: force,
                    },
                }),
            }),
            syncMailchimpUsers: builder.mutation<any, SyncMailchimpPutQuery>({
                query: (data: SyncMailchimpPutQuery) => {
                    const body = {
                        ...defaultSyncMailchimpOptions,
                        ...data,
                    };

                    return {
                        url: '/mailchimp/member/company/sync',
                        method: 'put',
                        body,
                    };
                },
            }),
            getCompanyComments: builder.query<CompanyComment[], any>({
                query: ({ companyId }) => {
                    return {
                        url: `/superadmin/company/${companyId}/comments`,
                        method: 'get',
                        params: {
                            OrderByDesc: 'Created',
                        },
                    };
                },
                providesTags: ['companyComments'],
            }),
            getCommentTypes: builder.query<CommentType[], void>({
                query: () => {
                    return {
                        url: `/superadmin/commentstype`,
                        method: 'get',
                    };
                },
            }),
            postCompanyComment: builder.mutation<any, CreateCompanyComment>({
                query: (payload) => {
                    return {
                        url: `/superadmin/company/${payload.CompanyId}/comments`,
                        method: 'post',
                        body: payload,
                    };
                },
                invalidatesTags: ['companyComments'],
            }),
            postCompanyApiKey: builder.mutation<any, any>({
                query: (payload) => {
                    return {
                        url: `/superadmin/apikey`,
                        method: 'post',
                        body: payload,
                    };
                },
                invalidatesTags: ['companyApiKey'],
            }),
            getIncentives: builder.query<GetIncentivesResponse, GetIncentivesQuery>({
                query: (payload) => {
                    return {
                        url: '/superadmin/incentives',
                        method: 'get',
                        params: {
                            ...payload,
                        },
                    };
                },
                providesTags: ['incentives'],
            }),
            postIncentive: builder.mutation<PostIncentiveResponse, PostIncentive>({
                query: (payload) => {
                    return {
                        url: '/superadmin/incentives',
                        method: 'post',
                        body: payload,
                    };
                },
                invalidatesTags: ['incentives'],
            }),
            getCompanyApiKey: builder.query<any, { CompanyId: string }>({
                query: ({ CompanyId }) => {
                    return {
                        url: `/superadmin/apikey/${CompanyId}`,
                        method: 'get',
                    };
                },
                providesTags: ['companyApiKey'],
            }),
            deleteCompanyComment: builder.mutation<any, { companyId: string; commentId: string }>({
                query: ({ companyId, commentId }) => {
                    return {
                        url: `/superadmin/company/${companyId}/comments/${commentId}`,
                        method: 'delete',
                    };
                },
                invalidatesTags: ['companyComments'],
            }),
        };
    },
});

// Toast listeneres

apiServiceStartListening({
    matcher: apiService.endpoints.syncMailchimpUsers.matchFulfilled,
    effect: async (action, api) => {
        toast.success('Success', { autoClose: 5000 });
    },
});

apiServiceStartListening({
    matcher: apiService.endpoints.syncMailchimpUsers.matchRejected,
    effect: async (action, api) => {
        // @ts-ignore
        if (action.payload?.data?.ResponseStatus?.Message) {
            // @ts-ignore
            toast.error(action.payload.data.ResponseStatus.Message, { autoClose: 5000 });
        } else if (action.payload?.status === 401) {
            toast.error(`${action.payload?.status} unauthorized`, { autoClose: 5000 });
        } else {
            // @ts-ignore
            toast.error(action.payload?.error, { autoClose: 5000 });
        }
    },
});

export const {
    useDeleteCompanyMutation,
    useGetCompanyQuery,
    useGetCompanyBookingsQuery,
    useGetCompanyAdministratorsQuery,
    useGetCompanyLicensesQuery,
    useGetCompanyInvoicesQuery,
    useSyncMailchimpUsersMutation,
    useGetCompanyCommentsQuery,
    useGetCommentTypesQuery,
    usePostCompanyCommentMutation,
    useDeleteCompanyCommentMutation,
    usePostCompanyApiKeyMutation,
    useGetCompanyApiKeyQuery,
    useGetIncentivesQuery,
    useLazyGetIncentivesQuery,
    usePostIncentiveMutation,
    useGetLicenseTypesQuery,
    useLazyGetLicenseTypesQuery,
} = apiService;
