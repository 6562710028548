/** @jsxImportSource theme-ui */
import { Flex, ThemeUICSSObject } from 'theme-ui';
import React, { useState } from 'react';
import { Box, Text } from 'theme-ui';
import CustomLink from '@/components/Link';
import { Popover } from '@component-controls/components';
import { Menu } from '@/containers/bookings/ActionsMenu';
import { UsersIcon, LogoutIcon } from '@heroicons/react/outline';
import { UsersIcon as UsersIconSolid, LogoutIcon as LogoutIconSolid } from '@heroicons/react/solid';
import { useSelector } from '@xstate/react';
import { AUTH_STORAGE_KEY, useAuthentication } from '@/providers/AuthenticationProvider';
import { getInitials } from '@/utils/helpers';
import localforage from 'localforage';

interface Props {
    collapsed: boolean;
}

const User: React.FC<Props> = (props) => {
    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
    const toggleUserMenu = () => setIsUserMenuOpen(!isUserMenuOpen);
    // @ts-ignore
    const { authService, keycloak } = useAuthentication();
    // @ts-ignore
    const user = useSelector(authService, (state) => state.context.user);

    return (
        <Flex onMouseLeave={() => setIsUserMenuOpen(false)}>
            <Popover
                arrowVisible={false}
                placement="top-start"
                trigger="focus"
                modifiers={[
                    {
                        enabled: true,
                        name: 'applyStyle',
                        phase: 'write',

                        fn({ state }) {
                            state.styles = {
                                popper: { ...state.styles.popper, zIndex: '20' },
                            };
                        },
                    },
                ]}
                tooltipShown={isUserMenuOpen}
                tooltip={() => (
                    <Menu>
                        <Flex
                            sx={{
                                flexDirection: 'column',
                                gap: 3,
                            }}
                        >
                            <CustomLink
                                sx={{
                                    textDecoration: 'none',
                                    color: 'gray.7',
                                    fontWeight: 500,
                                    display: 'flex',
                                    gap: 3,
                                    margin: 0,
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    backgroundColor: 'transparent',
                                }}
                                to={`/user/123`}
                                label={<Text>User Profile</Text>}
                                icon={[
                                    <UsersIcon width={16} height={16} />,
                                    <UsersIconSolid width={16} height={16} />,
                                ]}
                            />
                            <CustomLink
                                sx={{
                                    textDecoration: 'none',
                                    color: 'gray.7',
                                    fontWeight: 500,
                                    display: 'flex',
                                    gap: 3,
                                    margin: 0,
                                    alignItems: 'center',
                                }}
                                label={<Text>Logout</Text>}
                                icon={[
                                    <LogoutIcon width={16} height={16} />,
                                    <LogoutIconSolid width={16} height={16} />,
                                ]}
                                to="#"
                                onClick={(evt) => {
                                    keycloak.logout();
                                    localforage.removeItem(AUTH_STORAGE_KEY);
                                }}
                            />
                        </Flex>
                    </Menu>
                )}
            >
                {user ? (
                    <CustomLink
                        sx={{
                            cursor: 'pointer',
                            backgroundColor: 'transparent',
                        }}
                        collapsed={props.collapsed}
                        onClick={toggleUserMenu}
                        icon={[<UserInitials name={user.name} collapsed={props.collapsed} />]}
                        label={
                            <Flex
                                sx={{
                                    flexDirection: 'column',
                                }}
                            >
                                <Box>{user.name}</Box>
                                <Box as="sub">{user.email}</Box>
                            </Flex>
                        }
                    />
                ) : null}
            </Popover>
        </Flex>
    );
};

interface UserInitialsProps {
    name: string;
    collapsed: boolean;
}
export const UserInitials: React.FC<UserInitialsProps> = ({ name, collapsed, ...restProps }) => (
    <Text
        color="background"
        sx={{
            borderRadius: 'sm',
            backgroundColor: '#2DD4BF',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mr: collapsed ? 0 : 2,
            // p: 2,
            // minWidth: collapsed ? undefined : '30px',
            height: '30px',
            width: '30px',
            minWidth: '30px',
        }}
        {...restProps}
    >
        {getInitials(name)}
    </Text>
);

export default User;
