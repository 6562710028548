import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { Box, Grid, Field, Heading, Card, Flex, Textarea, Label } from 'theme-ui';
import { GrayFormikField } from '@/components/GrayFormikField';
import { usePostCompanyApiKeyMutation } from '@/store/apiService';
import Button from '@/components/Button';
import { useParams } from 'react-router-dom';
import Error from '@/components/Error';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

type Props = {};

const CreateApiKey: React.FC<Props> = (props: Props) => {
    const { id } = useParams();
    const [createApiKey, createApiKeyState] = usePostCompanyApiKeyMutation();
    let navigate = useNavigate();

    return (
        <div>
            <Formik
                initialValues={{
                    CompanyId: id,
                    FirstName: '',
                    LastName: '',
                    ContactPhone: '',
                    Notes: '',
                }}
                onSubmit={(values, { resetForm, setErrors, setSubmitting }) => {
                    createApiKey(values)
                        .unwrap()
                        .then((res) => {
                            navigate(`/clients/${id}`);
                        })
                        .catch((err) => {
                            setSubmitting(false);
                            toast.error(err.data, { autoClose: 5000 });
                        });
                }}
            >
                {(props) => {
                    return (
                        <>
                            <GrayFormikField name="CompanyId" type="text" label="Company Id" />
                            <GrayFormikField name="FirstName" type="text" label="First name" />
                            <GrayFormikField name="LastName" type="text" label="Last name" />
                            <GrayFormikField
                                name="ContactEmail"
                                type="text"
                                label="Contact email"
                            />
                            <GrayFormikField
                                name="ContactPhone"
                                type="text"
                                label="Contact phone"
                            />
                            <GrayFormikField
                                name="Notes"
                                type="textarea"
                                label="Notes"
                                placeholder="Purpose of usage"
                            />
                            <Flex sx={{ width: '100%', gap: 2 }}>
                                <Button
                                    sx={{ marginLeft: 'auto' }}
                                    isLoading={props.isSubmitting}
                                    disabled={props.isSubmitting}
                                    type="submit"
                                    onClick={props.submitForm}
                                >
                                    Save
                                </Button>
                            </Flex>
                        </>
                    );
                }}
            </Formik>
            {createApiKeyState.error ? (
                <>
                    <Error error={createApiKeyState.error as any} />
                </>
            ) : null}
        </div>
    );
};

export default CreateApiKey;
