import { definitions, operations, paths } from '@/apitypes';
import path from 'path/posix';

export type Company = operations['CompanyQuery_Get']['responses']['200']['schema']['Results'][0];

export type CompanyBooking =
    operations['BookingQuery_Get']['responses']['200']['schema']['Results'][0];

export type CompanyAdministrator =
    operations['SuperAdminCompanyUserQueryadministrators_Get']['responses']['200']['schema']['Results'][0];

export type CompanyLicense =
    operations['SuperAdminCompanyLicenseQuerylicensescompany_Get']['responses']['200']['schema']['Results'][0];

export type CompanyInvoice = definitions['CompanyInvoiceQueryResponse'];

export type InitialReducerState<T> = {
    entity: T;
    loading: boolean;
    error: any;
};

export type SyncMailchimpPutQuery = {
    DeleteNonExisting?: boolean;
    Test?: boolean;
    MemberCompanyId?: string;
};

export type CreateCompanyComment = {
    CompanyId: string;
    Comment: string;
    CommentType: string;
};
export interface CompanyComment {
    Id: string;
    CompanyId: string;
    Comment: string;
    CommentType: string;
    Updated: string;
    Created: string;
}

export type CommentType = {
    Id: number;
    Name: string;
    Description: string;
};

export type PostIncentive = definitions['CreateIncentive'];
export type PostIncentiveResponse = definitions['AdminIncentiveQueryResponse'];
export type Incentive = PostIncentiveResponse;
export type GetIncentivesQuery =
    operations['AdminIncentivesQueryincentives_Get']['parameters']['query'];
export type GetIncentivesResponse =
    definitions['QueryResponse_QueryResponse_AdminIncentiveQueryResponse__'];

export type GetLicenseTypesQuery = paths['/licenses/types/']['get']['parameters']['query'];
export type GetLicenseTypesResponse =
    paths['/licenses/types/']['get']['responses']['200']['schema'];

export enum IncentiveActionType {
    'Information' = 1,
    'Upgrade' = 2,
    'AddOn' = 3,
}

export type NestedKey<O extends Record<string, unknown>> = {
    [K in Extract<keyof O, string>]: O[K] extends Array<any>
        ? K
        : O[K] extends Record<string, unknown>
        ? `${K}` | `${K}.${NestedKey<O[K]>}`
        : K;
}[Extract<keyof O, string>];
