import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Text } from 'theme-ui';
import {
    useGetIncentivesQuery,
    useGetLicenseTypesQuery,
    useLazyGetLicenseTypesQuery,
    usePostIncentiveMutation,
} from '@/store/apiService';
import { useParams } from 'react-router-dom';
import Error from '@/components/Error';
import { useNavigate } from 'react-router-dom';
import { Incentive, IncentiveActionType } from '@/types/types';
import { toast } from 'react-toastify';
import IncentiveForm from './IncentiveForm';

type Props = {};

const DEFAULT_ACTION_ID = IncentiveActionType.Information;

const EditIncentive: React.FC<Props> = (props: Props) => {
    const { companyId, incentiveId } = useParams<{ companyId: string; incentiveId: string }>();
    const [createIncentive, createIncentiveState] = usePostIncentiveMutation();
    const incentives = useGetIncentivesQuery({});
    const licenseTypes = useGetLicenseTypesQuery({});
    const [actionId, setActionId] = useState<IncentiveActionType>(DEFAULT_ACTION_ID);

    const licenseTypesOptions = licenseTypes.data
        ? licenseTypes.data.Results.map((licenceType) => ({
              // @ts-ignore
              value: licenceType.Id,
              label: licenceType.Name,
          }))
        : [];
    let navigate = useNavigate();

    // @ts-ignore
    const incentive: Incentive = incentives.data?.Results.find((i) => i.Id === Number(incentiveId));

    useEffect(() => {
        if (incentive) {
            setActionId(incentive.ActionId);
        }
    }, [incentive]);

    if (!incentive) {
        return <Text>No incentive found</Text>;
    }

    // TODO: list all licenses and add tickboxes for inverted
    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    Heading: incentive.Heading,
                    StorageUrl: incentive.StorageUrl,
                    Frequency: incentive.Frequency,
                    InitialDelayInSeconds: incentive.InitialDelayInSeconds,
                    // @ts-ignore add after types are genarated
                    InitialDeferredDays: incentive.InitialDeferredDays,
                    MaxDisplayCount: incentive.MaxDisplayCount,
                    SuccessButtonText: incentive.SuccessButtonText,
                    ValidFrom: incentive.ValidFrom,
                    ValidTo: incentive.ValidTo,
                    Body: '',
                    RecurrenceInterval: incentive.RecurrenceInterval,
                    CompanyIds: incentive.CompanyIds ? incentive.CompanyIds : [''],
                    Criteria: incentive.Criteria,
                    ActionId: incentive.ActionId ? incentive.ActionId : DEFAULT_ACTION_ID,
                }}
                onSubmit={(values, { resetForm, setErrors, setSubmitting }) => {
                    createIncentive(values)
                        .unwrap()
                        .then((res) => {
                            navigate(`/clients/${companyId}`);
                        })
                        .catch((err) => {
                            setSubmitting(false);
                            toast.error(err.data, { autoClose: 5000 });
                        });
                }}
            >
                {(formProps) => {
                    return (
                        <IncentiveForm
                            actionId={actionId}
                            setActionId={setActionId}
                            licenseTypesOptions={licenseTypesOptions}
                            additionalErrors={createIncentiveState.error}
                            {...formProps}
                        />
                    );
                }}
            </Formik>
        </>
    );
};

export default EditIncentive;
