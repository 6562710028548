/** @jsxImportSource theme-ui */
import { Box, Text } from 'theme-ui';
import { Table } from '@component-controls/components';
import { isObject } from 'lodash';

export const CompanyApiKeyPartial = ({ apiKey }: { apiKey: any }) => {
    return (
        <Box
            variant="segment"
            sx={{
                borderRadius: 'sm',
                overflowY: 'scroll',
                zIndex: 2,
                justifySelf: 'left',
                width: '100%',
                backgroundColor: 'background',
                padding: 2,
                position: 'relative',
            }}
        >
            {isObject(apiKey) ? (
                <Table
                    columns={[
                        { columnKey: 'Company Id', accessor: 'CompanyId', Header: 'Company Id' },
                        { columnKey: 'ApiKey', accessor: 'ApiKey', Header: 'ApiKey' },
                        { columnKey: 'Notes', accessor: 'Notes', Header: 'Notes' },
                        {
                            columnKey: 'Internal notes',
                            accessor: 'InternalNotes',
                            Header: 'Internal Notes',
                        },
                    ]}
                    data={[apiKey]}
                />
            ) : (
                <Text>No API key found</Text>
            )}
        </Box>
    );
};
