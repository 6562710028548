/** @jsxImportSource theme-ui */
import { Field, Flex, Label, Box, Textarea, Checkbox, Text, Select } from 'theme-ui';
import {
    Field as FormikField,
    FieldArray as FormikFieldArray,
    FormikProps,
    FieldAttributes,
    useFormikContext,
} from 'formik';
import { validateRequired } from '@/utils/validators';
import { TrashIcon } from '@heroicons/react/outline';
import Button from '@/components/Button';

interface Option {
    value: string | number;
    label: string;
}

type GrayFormikProps =
    | {
          type: 'select';
          label: string;
          options: Option[];
      }
    | {
          type: string;
          label: string;
      };
export const GrayFormikField: React.FC<GrayFormikProps & FieldAttributes<any>> = ({ ...props }) => {
    const { errors } = useFormikContext();

    if (props.type === 'array') {
        // @ts-ignore
        return <FormikFieldArray backgroundColor="gray.1" {...props} />;
    }

    if (props.type === 'textarea') {
        return (
            <Box mb="2">
                <Label>
                    {props.label}
                    {props.required ? (
                        <Text color="danger" ml="1">
                            *
                        </Text>
                    ) : null}
                </Label>

                <FormikField
                    backgroundColor="gray.1"
                    rows="5"
                    as={Textarea}
                    sx={{
                        maxWidth: '100%',
                        minWidth: '100%',
                    }}
                    {...props}
                    {...(props.required ? { validate: validateRequired } : {})}
                />
                {props.name && errors && errors[props.name] ? (
                    <Text color="danger">{errors[props.name]}</Text>
                ) : null}
            </Box>
        );
    }

    if (props.type === 'select') {
        return (
            <Box mb="2">
                <FormikField
                    backgroundColor="gray.1"
                    as={Select}
                    {...props}
                    {...(props.required ? { validate: validateRequired } : {})}
                >
                    {props.options.map((option, index) => (
                        <option key={index} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </FormikField>
            </Box>
        );
    }

    if (props.type === 'checkbox') {
        return (
            <Label
                mb="3"
                sx={{
                    cursor: 'pointer',
                    fontSize: 3,
                    color: 'primary',
                }}
            >
                <FormikField
                    as={Checkbox}
                    {...props}
                    {...(props.required ? { validate: validateRequired } : {})}
                />
                <Text>{props.label}</Text>
            </Label>
        );
    }

    return (
        <Box mb="2">
            <FormikField
                backgroundColor="gray.1"
                {...props}
                as={Field}
                label={
                    <>
                        {props.label}
                        {props.required ? (
                            <Text color="danger" ml="1">
                                *
                            </Text>
                        ) : null}
                    </>
                }
                {...(props.required ? { validate: validateRequired } : {})}
            />
            {props.name && errors && errors[props.name] ? (
                <Text color="danger">{errors[props.name]}</Text>
            ) : null}
        </Box>
    );
};

interface GrayFormikArrayFieldProps extends FormikProps<any> {
    name: string;
    label?: string;
}
export const GrayFormikArrayField: React.FC<GrayFormikArrayFieldProps> = ({
    children,
    ...props
}) => {
    return (
        <>
            {props.name ? <Label>{props.name}</Label> : null}
            <FormikFieldArray
                {...props}
                render={(arrayHelpers) => {
                    {
                        return props.values[props.name] && props.values[props.name].length > 0
                            ? props.values[props.name].map((_, index) => (
                                  <>
                                      <Flex
                                          key={index}
                                          sx={{
                                              gap: 3,
                                              alignItems: 'flex-end',
                                              flexGrow: 1,
                                          }}
                                      >
                                          <GrayFormikField
                                              as={Field}
                                              name={`${props.name}.${index}`}
                                              type="string"
                                          />

                                          <Flex
                                              sx={{
                                                  alignItems: 'flex-end',
                                                  pb: 2,
                                                  gap: 1,
                                              }}
                                          >
                                              <Button
                                                  variant="danger"
                                                  type="button"
                                                  onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                              >
                                                  <TrashIcon width={16} height={16} />
                                              </Button>
                                          </Flex>
                                      </Flex>
                                      {props.values.CompanyIds.length === index + 1 ? (
                                          <Button
                                              variant="secondary"
                                              type="button"
                                              onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                                          >
                                              Add company Id
                                          </Button>
                                      ) : null}
                                  </>
                              ))
                            : null;
                    }
                }}
            />
        </>
    );
};
