import React, { useEffect } from 'react';
import { FieldArray, FormikProps } from 'formik';
import { Flex, Label, Box, Heading, Field, Card, Container } from 'theme-ui';
import { GrayFormikField } from '@/components/GrayFormikField';
import { TrashIcon } from '@heroicons/react/outline';
import Button from '@/components/Button';
import { GetLicenseTypesResponse, IncentiveActionType } from '@/types/types';
import CustomDatePicker from '@/components/CustomDatePicker';
import Error from '@/components/Error';

const incentiveActionTypesOptions: {
    value: IncentiveActionType;
    label: string;
}[] = [
    { value: 1, label: 'Information' },
    { value: 2, label: 'Upgrade' },
    { value: 3, label: 'AddOn' },
];

interface LicenceTypeOption {
    value: number;
    label: string;
}
interface IncentiveFormMainProps extends FormikProps<any> {
    actionId: IncentiveActionType;
    setActionId: (arg: IncentiveActionType) => void;
    licenseTypesOptions: LicenceTypeOption[];
    additionalErrors?: React.ReactNode;
    addToFooter?: React.ReactNode;
}

export const getLicenseTypeOptions = (licenseTypes: GetLicenseTypesResponse) =>
    licenseTypes
        ? licenseTypes.Results.map((licenceType) => ({
              // @ts-ignore
              value: licenceType.Id,
              label: licenceType.Name,
          }))
        : [];

export const IncentiveFormMain: React.FC<IncentiveFormMainProps> = (props) => {
    return (
        <>
            <GrayFormikField name="Heading" type="text" label="Heading" />
            <GrayFormikField name="StorageUrl" type="text" label="Storage URL" />
            <GrayFormikField name="SuccessButtonText" type="text" label="Success button text" />
            <GrayFormikField name="Frequency" type="text" label="Frequency" />
            <GrayFormikField
                name="InitialDelayInSeconds"
                type="number"
                label="Initial delay in seconds after login"
            />
            <GrayFormikField
                name="InitialDeferredDays"
                type="number"
                label="Show after number of days from first login"
            />
            <GrayFormikField
                name="MaxDisplayCount"
                type="text"
                label="Maximum number of appearances"
            />

            <Label>Valid from</Label>
            <CustomDatePicker
                variation="normal"
                field={{
                    name: 'ValidFrom',
                    value: !props.values.ValidFrom ? null : new Date(props.values.ValidFrom),
                }}
                onChange={(value) => {
                    props.setFieldValue('ValidFrom', value.toISOString());
                }}
            />

            <Label>Valid to</Label>
            <CustomDatePicker
                variation="normal"
                field={{
                    name: 'ValidTo',
                    value: !props.values.ValidTo ? null : new Date(props.values.ValidTo),
                }}
                onChange={(value) => {
                    props.setFieldValue('ValidTo', value.toISOString());
                }}
            />

            <Field
                backgroundColor="gray.1"
                type="file"
                label="Upload HTML"
                onChange={(e: any) => {
                    e.currentTarget.files[0].text().then((res) => {
                        props.setFieldValue('Body', res);
                    });
                }}
            />

            <GrayFormikField name="RecurrenceInterval" type="number" label="Recurrence interval" />

            <Label>Company Ids</Label>
            <FieldArray
                name="CompanyIds"
                render={({ insert, remove, name }) => {
                    return (
                        <>
                            {props.values.CompanyIds.length === 0 ? (
                                <Button
                                    variant="secondary"
                                    type="button"
                                    onClick={() => insert(0, '')}
                                >
                                    Add company Id
                                </Button>
                            ) : (
                                props.values.CompanyIds.map((companyId, index) => {
                                    return (
                                        <>
                                            <Flex
                                                sx={{
                                                    alignItems: 'center',
                                                    gap: 2,
                                                }}
                                                key={index}
                                            >
                                                <Box
                                                    sx={{
                                                        flexGrow: 1,
                                                    }}
                                                >
                                                    <GrayFormikField name={`CompanyIds.${index}`} />
                                                </Box>
                                                <Button
                                                    variant="danger"
                                                    type="button"
                                                    onClick={() => remove(index)} // remove a friend from the list
                                                >
                                                    <TrashIcon width={16} height={16} />
                                                </Button>
                                            </Flex>
                                            {props.values.CompanyIds.length === index + 1 ? (
                                                <Button
                                                    variant="secondary"
                                                    type="button"
                                                    onClick={() => insert(index + 1, '')}
                                                >
                                                    Add company Id
                                                </Button>
                                            ) : null}
                                        </>
                                    );
                                })
                            )}
                        </>
                    );
                }}
            />
        </>
    );
};

interface CriteriaFieldProps extends FormikProps<any> {
    licenceTypeOptions: LicenceTypeOption[];
}
const CriteriaField: React.FC<CriteriaFieldProps> = ({ licenceTypeOptions, setFieldValue }) => {
    useEffect(() => {
        licenceTypeOptions.forEach((option, index) => {
            setFieldValue(`Criteria.${index}.checked`, false);
            setFieldValue(`Criteria.${index}.Value`, option.value);
            setFieldValue(`Criteria.${index}.CriteriaType`, 'IncentiveLicenseCriteria');
            setFieldValue(`Criteria.${index}.InvertCondition`, false);
        });

        return () => {
            licenceTypeOptions.forEach((option, index) => {
                setFieldValue(`Criteria.${index}.checked`, undefined);
                setFieldValue(`Criteria.${index}.Value`, undefined);
                setFieldValue(`Criteria.${index}.CriteriaType`, undefined);
                setFieldValue(`Criteria.${index}.InvertCondition`, undefined);
            });
        };
    }, [licenceTypeOptions.map((o) => o.value).join(',')]);

    return (
        <>
            {licenceTypeOptions.map((licenseType, index) => {
                return (
                    <Flex
                        sx={{
                            alignItems: 'center',
                            gap: 2,
                        }}
                        key={`${licenseType.value}-${index}`}
                    >
                        <GrayFormikField
                            type="checkbox"
                            label={licenseType.label}
                            name={`Criteria.${index}.checked`}
                        />

                        <GrayFormikField
                            type="checkbox"
                            label="Inverted"
                            name={`Criteria.${index}.InvertCondition`}
                        />
                    </Flex>
                );
            })}
        </>
    );
};

interface IncentiveFormCriteriaProps extends FormikProps<any> {
    licenseTypesOptions: LicenceTypeOption[];
}
export const IncentiveFormCriteria: React.FC<IncentiveFormCriteriaProps> = ({
    licenseTypesOptions,
    ...props
}) => {
    return (
        <>
            <Heading as="h3">Criteria</Heading>
            <CriteriaField licenceTypeOptions={licenseTypesOptions} {...props} />
        </>
    );
};

const IncentiveForm: React.FC<IncentiveFormMainProps & IncentiveFormCriteriaProps> = ({
    actionId,
    setActionId,
    licenseTypesOptions,
    additionalErrors,
    addToFooter,
    ...formProps
}) => {
    return (
        <Flex
            backgroundColor="Background"
            p="2"
            sx={{ flexDirection: 'column', gap: 2, borderRadius: 'sm' }}
        >
            <Flex
                sx={{
                    gap: 2,
                    '@media only screen and (max-width:60em)': {
                        flexDirection: 'column',
                    },
                    borderRadius: 'sm',
                }}
            >
                <Box
                    sx={{
                        flexBasis: '50%',
                        borderRadius: 'sm',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderColor: 'gray.2',
                        alignSelf: 'flex-start',
                    }}
                    p="3"
                >
                    <IncentiveFormMain
                        actionId={actionId}
                        setActionId={setActionId}
                        licenseTypesOptions={licenseTypesOptions}
                        {...formProps}
                    />
                </Box>
                <Box
                    sx={{
                        flexBasis: '50%',
                        borderRadius: 'sm',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderColor: 'gray.2',
                        alignSelf: 'flex-start',
                    }}
                    p="3"
                >
                    <IncentiveFormCriteria
                        licenseTypesOptions={licenseTypesOptions}
                        {...formProps}
                    />
                </Box>
            </Flex>
            {additionalErrors ? (
                <Container variant="error-message">
                    <Error error={additionalErrors as any} />
                </Container>
            ) : null}
            <Flex
                sx={{
                    width: '100%',
                    gap: 2,
                }}
            >
                {addToFooter}
                <Button
                    isLoading={formProps.isSubmitting}
                    disabled={formProps.isSubmitting}
                    type="submit"
                    onClick={formProps.submitForm}
                >
                    Save
                </Button>
            </Flex>
        </Flex>
    );
};

export default IncentiveForm;
