/** @jsxImportSource theme-ui */
import { ThemeProvider } from '@theme-ui/core';
import { HashRouter as Router } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Provider as ReduxProvider } from 'react-redux';
import { theme } from './theme/index';
import AuthenticationProvider from '@/providers/AuthenticationProvider';
import Homepage from '@/Pages/Homepage';
import UIMachineProvider from './providers/UIProvider';
import localforage from 'localforage';
import { Global } from '@emotion/react';
import { inspect } from '@xstate/inspect';
import { Provider as ReakitProvider } from 'reakit';
import * as system from 'reakit-system-bootstrap';

import 'react-toastify/dist/ReactToastify.css';
import { createStore } from '@/store';

const store = createStore();

// if (process.env.NODE_ENV === 'development') {
//     inspect({
//         iframe: false,
//     });
// }

localforage.config({
    name: 'bm-superadmin-ui',
    version: 1.0,
    storeName: 'keyvaluepairs',
});

function App() {
    return (
        <div className="App">
            <ReduxProvider store={store}>
                {/* @ts-ignore */}
                <ThemeProvider theme={theme}>
                    <ToastContainer />
                    <Router>
                        <AuthenticationProvider>
                            <UIMachineProvider>
                                <Global
                                    styles={{
                                        html: {
                                            fontSize: '14px',
                                        },
                                        body: {
                                            margin: 0,
                                            padding: 0,
                                            boxSizing: 'border-box',
                                            background: '#E5E5E5!important',
                                            // overflow: 'hidden',
                                        },
                                    }}
                                />
                                <ReakitProvider unstable_system={system}>
                                    <Homepage />
                                </ReakitProvider>
                            </UIMachineProvider>
                        </AuthenticationProvider>
                    </Router>
                </ThemeProvider>
            </ReduxProvider>
        </div>
    );
}

export default App;
