import { Text, get, Flex, Link, Box, Heading, Badge } from 'theme-ui';
import { Company } from '../containers/companyDetails/types';
import { UserAddIcon, GlobeIcon, TrashIcon, ServerIcon, BellIcon } from '@heroicons/react/outline';
import { LoginAsAdmin } from '@/containers/companies/LoginAsAdmin';
import { setCompanyId } from '@/store/deleteCompany';
import { useDispatch } from 'react-redux';

export const CompanyHeader = (company: Company) => {
    const dispatch = useDispatch();

    return (
        <Box mb="3">
            <Flex>
                <Box
                    mr="3"
                    sx={{
                        width: '80px',
                        height: '80px',
                        borderRadius: 'xsm',
                        backgroundImage: `url('${company.LogoType}')`,
                        backgroundPosition: 'top top',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        border: (t) => `1px solid ${get(t, 'colors.gray.2')}`,
                    }}
                />
                <Box>
                    <Heading>{company.Name}</Heading>
                    {company.Active ? (
                        <Badge mb="2" variant="active">
                            Active
                        </Badge>
                    ) : null}
                    <Flex sx={{ flexWrap: 'wrap', gap: 2 }}>
                        <LoginAsAdmin companyId={company.Id} variant="header" />
                        <Link
                            variant="header"
                            target="_blank"
                            href={`https://${company?.SitePath}.${process.env.REACT_APP_COMPANY_HOMEPAGE_DOMAIN_NAME}`}
                        >
                            <GlobeIcon width={16} height={16} />
                            <Text>Navigate to website</Text>
                        </Link>
                        <Link variant="header" href={`/#/apikeys/${company.Id}`}>
                            <ServerIcon width={16} height={16} />
                            <Text>Create API key</Text>
                        </Link>
                        <Link variant="header" href={`/#/clients/${company.Id}/incentives/new`}>
                            <BellIcon width={16} height={16} />
                            <Text>Create incentive</Text>
                        </Link>
                        <Link
                            variant="header"
                            href={`#`}
                            color="danger"
                            onClick={(evt) => {
                                evt.preventDefault();
                                dispatch(setCompanyId(company.Id));
                            }}
                        >
                            <TrashIcon width={16} height={16} color="danger" />
                            <Text color="danger">Delete</Text>
                        </Link>
                    </Flex>
                </Box>
            </Flex>
        </Box>
    );
};
