/** @jsxImportSource theme-ui */
import { Text, Alert, Card, Spinner, Grid, Box, Button, Flex } from 'theme-ui';
import { useEffect } from 'react';
import { useMachine } from '@xstate/react';
import companyMachine, {
    LOAD_COMPANY,
    getError,
    getIsLoading,
    getCompany,
} from './machines/company/companyInfo';
import { useParams } from 'react-router-dom';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { CompanyInfoPartial } from './CompanyInfoPartial';
import { CompanyHeader } from '@/components/CompanyHeader';
import { CompanyComments } from '@/components/CompanyComments';

const GOOGLE_MAPS_API_KEY = window.GOOGLE_MAPS_API_KEY;
export const GOOGLE_MAP_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`;

export const Map = withScriptjs(
    withGoogleMap(({ lat, lng }: { lat: string; lng: string }) => (
        <GoogleMap defaultZoom={14} center={{ lat, lng }}>
            <Marker position={{ lat, lng }} />
        </GoogleMap>
    ))
);

const CompanyInfo = () => {
    const { id } = useParams();
    const [state, send] = useMachine(companyMachine);
    const company = getCompany(state);
    const isLoading = getIsLoading(state);
    const error = getError(state);

    useEffect(() => {
        send({ type: LOAD_COMPANY, payload: { CompanyId: id } });
    }, [id]);

    if (!company) {
        return <Spinner size={16} />;
    }

    return (
        <>
            <CompanyHeader {...company} />
            <Flex
                sx={{
                    gap: 2,
                    '@media only screen and (max-width:60em)': {
                        flexDirection: 'column',
                    },
                }}
            >
                <Box sx={{ flexBasis: '50%' }}>
                    {isLoading ? <Spinner size={16} /> : null}
                    <CompanyInfoPartial {...company} />
                    {error ? (
                        <Card variant="widget">
                            <Text color="danger">{error}</Text>
                        </Card>
                    ) : null}
                </Box>
                <Box sx={{ flexBasis: '50%' }}>
                    <CompanyComments />
                </Box>
            </Flex>
        </>
    );
};

export default CompanyInfo;
