/** @jsxImportSource theme-ui */
import { Container, Box, Grid, Flex } from 'theme-ui';
import { useNavigate } from 'react-router-dom';
import Incentives from '@/containers/incentives/Incentives';
import Button from '@/components/Button';

const IncentivePage = () => {
    let navigate = useNavigate();
    return (
        <Container>
            <Flex sx={{ flexDirection: 'column', gap: 2 }}>
                <Flex sx={{ justifyContent: 'flex-end' }}>
                    <Button
                        onClick={() => {
                            navigate('/incentives/new');
                        }}
                    >
                        Add incentive
                    </Button>
                </Flex>

                <Box
                    sx={{
                        borderRadius: 'sm',
                        overflowY: 'scroll',
                        zIndex: 2,
                        justifySelf: 'left',
                        width: '100%',
                        backgroundColor: 'background',
                        padding: 2,
                        position: 'relative',
                    }}
                >
                    <Incentives />
                </Box>
            </Flex>
        </Container>
    );
};

export default IncentivePage;
