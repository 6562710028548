/** @jsxImportSource theme-ui */
import { Text, Box, Card, Spinner, Heading } from 'theme-ui';
import { useParams } from 'react-router-dom';

import { CompanyApiKeyPartial } from './CompanyApiKeyPartial';
import { useGetCompanyApiKeyQuery } from '@/store/apiService';
import Error from '@/components/Error';

const CompanyApiKey = () => {
    const { id } = useParams();
    const { isLoading, data: apiKey, error } = useGetCompanyApiKeyQuery({ CompanyId: id });

    return (
        <Box>
            <Heading>Api key</Heading>
            {isLoading ? <Spinner size={16} /> : null}
            <CompanyApiKeyPartial apiKey={apiKey} />
            {error ? (
                <Card variant="widget">
                    <Error error={error as any} />
                </Card>
            ) : null}
        </Box>
    );
};

export default CompanyApiKey;
