/** @jsxImportSource theme-ui */
import {
    Flex,
    Heading,
    Box,
    Spinner,
    Grid,
    Container,
    Label,
    Checkbox,
    Text,
    Link,
} from 'theme-ui';
import { toast } from 'react-toastify';
import { get } from 'theme-ui';
import { useNavigate } from 'react-router-dom';
import Button from '@/components/Button';
import React, { useEffect, useState } from 'react';
import { useDialogState, Dialog, DialogBackdrop } from 'reakit/Dialog';
import {
    useDeleteCompanyMutation,
    useGetCompanyAdministratorsQuery,
    useGetCompanyBookingsQuery,
    useGetCompanyInvoicesQuery,
    useGetCompanyLicensesQuery,
    useGetCompanyQuery,
} from '@/store/apiService';
import { setCompanyId } from '@/store/deleteCompany';
import { useDispatch, useSelector } from 'react-redux';
import Error from '@/components/Error';
import { ConfirmationModal } from '../../components/ConfirmationModal';
import { SuccessModal } from './SuccessModal';
import { CompanyOverview } from './CompanyOverview';
import { theme } from '../../theme';

interface Props {}

const DeleteCompanyModal: React.FC<Props> = (props) => {
    const companyId = useSelector((s: any) => s.deleteCompany.companyId);

    const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
    const [iContacted, setIcontacted] = useState(false);
    const [iAware, setIAware] = useState(false);
    const dispatch = useDispatch();
    const dialog = useDialogState();
    const confirmErrorDeleteDialog = useDialogState();
    const company = useGetCompanyQuery({ Id: companyId }, { skip: !companyId });
    const [deleteCompany, deleteCompanyState] = useDeleteCompanyMutation();
    let navigate = useNavigate();

    const bookings = useGetCompanyBookingsQuery(
        { CompanyId: companyId, Take: 100 },
        { skip: !companyId }
    );
    const companyLicenses = useGetCompanyLicensesQuery(
        { CompanyId: companyId },
        { skip: !companyId }
    );

    const companyInvoices = useGetCompanyInvoicesQuery(
        { CompanyId: companyId },
        { skip: !companyId }
    );

    const administrators = useGetCompanyAdministratorsQuery({ CompanyId: companyId });

    useEffect(() => {
        if (!deleteCompanyState.isUninitialized && companyId && !confirmErrorDeleteDialog.visible) {
            dispatch(setCompanyId());
            deleteCompanyState.reset();
        }

        if (confirmErrorDeleteDialog.visible) {
            dialog.hide();
        }
        if (!confirmErrorDeleteDialog.visible) {
            setIAware(false);
        }
        // TODO: add deleteCompanyState.isUninitialized to the dependencies and
        // debug why it's hiding the confirmErrorDeleteDialog when we add it
    }, [confirmErrorDeleteDialog.visible]);

    useEffect(() => {
        if (companyId) {
            dialog.show();
        }
    }, [companyId]);

    useEffect(() => {
        return () => {
            deleteCompanyState.reset();
            dispatch(setCompanyId());
        };
    }, []);

    useEffect(() => {
        // @ts-ignore
        if (deleteCompanyState.error?.data?.ResponseStatus?.Errors) {
            confirmErrorDeleteDialog.show();
        }

        if (deleteCompanyState.isSuccess) {
            deleteCompanyState.reset();
            navigate('/clients');
            toast('Successfully deleted', {
                hideProgressBar: true,
                bodyStyle: {
                    color: get(theme, 'colors.success'),
                },
            });
        }
    }, [deleteCompanyState.error, deleteCompanyState.isSuccess]);

    const companyLoaded =
        !company.isFetching &&
        !bookings.isFetching &&
        !administrators.isFetching &&
        company.isSuccess &&
        bookings.isSuccess &&
        administrators.isSuccess;

    const companyLoading =
        company.isFetching ||
        bookings.isFetching ||
        administrators.isFetching ||
        company.isLoading ||
        bookings.isLoading ||
        administrators.isLoading;

    return (
        <>
            <DialogBackdrop {...dialog}>
                {companyLoaded && !deleteCompanyState.isSuccess ? (
                    <Dialog
                        sx={{
                            '&&': {
                                padding: 4,
                                pb: '70px',
                                minHeight: '480px',
                                overflow: 'scroll',
                            },
                        }}
                        {...dialog}
                    >
                        {confirmationModalVisible ? (
                            <ConfirmationModal
                                content="This action can not be reversed, all company data will be permanetelly deleted!!"
                                onNo={() => {
                                    setConfirmationModalVisible(false);
                                }}
                                onYes={() => {
                                    deleteCompany({ companyId, gotApprovedByAdmin: iContacted });
                                    setConfirmationModalVisible(false);
                                }}
                            />
                        ) : null}

                        <Grid
                            pb="3"
                            sx={{
                                height: '80vh',
                                overflowY: 'scroll',
                                gridTemplateRows: 'auto auto auto auto auto',
                                gap: 3,
                            }}
                        >
                            {/* Heading */}
                            <Heading>You're about to delete the following company</Heading>

                            <CompanyOverview
                                company={company.data}
                                companyBookings={bookings.data?.Results}
                                companyAdministrators={administrators.data?.Results}
                                companyLicenses={companyLicenses.data}
                                companyInvoices={companyInvoices.data.Results}
                            />

                            {/* Checkbox  */}
                            <Box mt="3">
                                <Label
                                    sx={{
                                        cursor: 'pointer',
                                        fontSize: 3,
                                        color: 'primary',
                                    }}
                                >
                                    <Checkbox
                                        sx={{ cursor: 'pointer' }}
                                        name="iContacted"
                                        type="checkbox"
                                        onChange={(evt) => {
                                            setIcontacted(evt.target.checked);
                                        }}
                                    />
                                    <Box ml={2}>
                                        Have you contacted the administrator and got a written
                                        verification by email they want to have their account
                                        deleted, email should be saved with Gmail label
                                    </Box>
                                </Label>
                            </Box>

                            {/* Error */}
                            {deleteCompanyState.isError ? (
                                <Box mb="3">
                                    <Container variant="error-message">
                                        <Error error={deleteCompanyState.error as any} />
                                    </Container>
                                </Box>
                            ) : null}

                            {/* Buttons */}
                            <Box sx={{ position: 'fixed', left: 4, right: 4, bottom: 4 }}>
                                <Flex sx={{ gap: 3, justifyContent: 'flex-end' }}>
                                    <Button variant="secondary" onClick={dialog.hide}>
                                        Cancel
                                    </Button>
                                    <Button
                                        disabled={!iContacted}
                                        isLoading={deleteCompanyState.isLoading}
                                        variant="danger"
                                        onClick={() => {
                                            setConfirmationModalVisible(true);
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </Flex>
                            </Box>
                        </Grid>
                    </Dialog>
                ) : null}
                {companyLoading ? (
                    <Dialog
                        {...dialog}
                        sx={{
                            '&&': {
                                padding: 4,
                            },
                        }}
                    >
                        <Spinner />
                    </Dialog>
                ) : null}
                {deleteCompanyState.isSuccess ? <SuccessModal onOk={dialog.hide} /> : null}
            </DialogBackdrop>
            <DialogBackdrop {...confirmErrorDeleteDialog}>
                <Dialog
                    sx={{
                        '&&': {
                            padding: 4,
                            pb: '70px',
                            minHeight: '480px',
                            overflow: 'scroll',
                        },
                    }}
                    {...confirmErrorDeleteDialog}
                >
                    <Flex sx={{ flexDirection: 'column', gap: 2 }}>
                        <Heading sx={{ fontSize: 4 }}>
                            You're about to delete a company permanently
                        </Heading>
                        <Text>The following errors prevented the deletion of the company</Text>
                        {deleteCompanyState.isError ? (
                            <Box mb="3">
                                <Container variant="error-message">
                                    <Error error={deleteCompanyState.error as any} />
                                </Container>
                            </Box>
                        ) : null}
                        <Text>
                            If you still want to delete the company, first you need to fill the form
                            by clicking on the link below
                        </Text>

                        <Text>
                            <Link
                                href="https://docs.google.com/spreadsheets/d/1LnmZV8qyZD5jFwzZlPsSWlHd0N7N23Ic6ETA0jDUQ1I/edit?gid=247016812#gid=247016812"
                                target="_blank"
                            >
                                Company delete form
                            </Link>
                        </Text>
                        <Text>and then confirm the checkbox below</Text>

                        <Label
                            sx={{
                                cursor: 'pointer',
                                fontSize: 3,
                                color: 'primary',
                            }}
                        >
                            <Checkbox
                                sx={{ cursor: 'pointer' }}
                                name="iAware"
                                checked={iAware}
                                type="checkbox"
                                onChange={(evt) => {
                                    setIAware(evt.target.checked);
                                }}
                            />
                            <Box ml={2}>I'm aware company will be deleted permanently</Box>
                        </Label>
                    </Flex>
                    <Flex sx={{ justifyContent: 'flex-end' }}>
                        <Button
                            disabled={!iAware}
                            isLoading={deleteCompanyState.isLoading}
                            variant="danger"
                            onClick={() => {
                                deleteCompany({
                                    companyId,
                                    gotApprovedByAdmin: iContacted,
                                    force: true,
                                });
                            }}
                        >
                            Delete
                        </Button>
                    </Flex>
                </Dialog>
            </DialogBackdrop>
        </>
    );
};

export default DeleteCompanyModal;
