import React from 'react';

const METABASE_SITE_URL = process.env.REACT_APP_METABASE_SITE_URL;
const iframeUrl = METABASE_SITE_URL + '/public/dashboard/0b579ed0-7368-4d4f-81b4-dd02b71c225d';

export const MetabaseWeeklySales: React.FC = () => {
    return (
        <iframe src={iframeUrl} frameBorder="none" width="100%" height="600" allowTransparency />
    );
};
