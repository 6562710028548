/** @jsxImportSource theme-ui */
import { Container, Heading, Box, Flex } from 'theme-ui';
import React from 'react';
import Button from '@/components/Button';
import { PostIncentive } from '@/types/types';
import { isValidUrl } from '@/utils/helpers';

interface Props extends PostIncentive {}

const PreviewIncentive: React.FC<Props> = (props) => {
    return (
        <Flex sx={{ gap: 3, flexDirection: 'column' }}>
            <Heading as="h3">{props.Heading}</Heading>
            {props.Body ? (
                <Box sx={{ minHeight: '480px' }} dangerouslySetInnerHTML={{ __html: props.Body }} />
            ) : isValidUrl(props.StorageUrl) ? (
                <iframe
                    src={props.StorageUrl}
                    height="640px"
                    width={'100%'}
                    frameBorder="none"
                    scrolling="true"
                />
            ) : null}
            {props.SuccessButtonText ? (
                <Flex sx={{ justifyContent: 'flex-end' }}>
                    <Button>{props.SuccessButtonText}</Button>
                </Flex>
            ) : null}
        </Flex>
    );
};

export default PreviewIncentive;
