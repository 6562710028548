/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useDialogState, Dialog, DialogBackdrop } from 'reakit/Dialog';
import { useLazyGetLicenseTypesQuery, usePostIncentiveMutation } from '@/store/apiService';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Button from '@/components/Button';
import { IncentiveActionType, PostIncentive } from '@/types/types';
import { toast } from 'react-toastify';
import IncentiveForm from './IncentiveForm';
import PreviewIncentive from './PreviewIncentive';

type Props = {};

const DEFAULT_ACTION_ID = IncentiveActionType.Information;

const CreateIncentive: React.FC<Props> = (props: Props) => {
    const { companyId } = useParams<{ companyId: string }>();
    const [createIncentive, createIncentiveState] = usePostIncentiveMutation();
    const [getLicenseTypes, licenseTypes] = useLazyGetLicenseTypesQuery({});
    const [actionId, setActionId] = useState<IncentiveActionType>(DEFAULT_ACTION_ID);
    const dialog = useDialogState();

    const licenseTypesOptions = licenseTypes.data
        ? licenseTypes.data.Results.map((licenceType) => ({
              // @ts-ignore
              value: licenceType.Id,
              label: licenceType.Name,
          }))
        : [];
    let navigate = useNavigate();

    const initialValues: PostIncentive = {
        Heading: '',
        StorageUrl: '',
        Frequency: '',
        InitialDelayInSeconds: 0,
        // @ts-ignore add after types are genarated
        InitialDeferredDays: 0,
        MaxDisplayCount: 0,
        SuccessButtonText: '',
        ValidFrom: '',
        ValidTo: '',
        Body: '',
        RecurrenceInterval: 0,
        CompanyIds: companyId ? [companyId] : [],
        Criteria: [],
        ActionId: DEFAULT_ACTION_ID,
    };

    useEffect(() => {
        getLicenseTypes({});
    }, [actionId]);

    return (
        <>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={(values, { resetForm, setErrors, setSubmitting }) => {
                    const sanitizedFormValues = {
                        ...values,
                        // @ts-ignore
                        Criteria: values.Criteria.filter((c) => c.checked),
                    };

                    createIncentive(sanitizedFormValues)
                        .unwrap()
                        .then((res) => {
                            navigate(`/clients/${companyId}`);
                        })
                        .catch((err) => {
                            setSubmitting(false);
                            toast.error(err.data, { autoClose: 5000 });
                        });
                }}
            >
                {(formProps) => {
                    return (
                        <>
                            <IncentiveForm
                                {...formProps}
                                actionId={actionId}
                                setActionId={setActionId}
                                licenseTypesOptions={licenseTypesOptions}
                                additionalErrors={createIncentiveState.error}
                                addToFooter={
                                    <Button
                                        variant="secondary"
                                        sx={{
                                            marginLeft: 'auto',
                                        }}
                                        onClick={() => dialog.show()}
                                    >
                                        Preview incentive
                                    </Button>
                                }
                            />

                            <DialogBackdrop {...dialog}>
                                <Dialog
                                    sx={{
                                        width: '2/3',
                                        maxWidth: '640px',
                                        '&&': {
                                            padding: 4,
                                            minHeight: '480px',
                                            overflow: 'scroll',
                                        },
                                    }}
                                    {...dialog}
                                >
                                    <PreviewIncentive {...formProps.values} />
                                </Dialog>
                            </DialogBackdrop>
                        </>
                    );
                }}
            </Formik>
        </>
    );
};

export default CreateIncentive;
