import { Card, Container, Grid, Flex } from 'theme-ui';
import React from 'react';
import { SyncAllCompaniesMailchimpUsers } from './SyncAllCompaniesMailchimpUsers';
import { MetabaseWeeklySales } from './MetabaseWeeklySales';

interface Props {}

const FavoriteActions: React.FC<Props> = (props) => {
    const mailchimpEnabled = localStorage.getItem('mailchimp');

    return (
        <Container>
            <Flex sx={{ gap: 3, flexDirection: 'column' }}>
                <Card variant="widget" p={3}>
                    <MetabaseWeeklySales />
                </Card>
                <Grid sx={{ gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr) )' }}>
                    {mailchimpEnabled ? (
                        <Card variant="widget" p={3}>
                            <SyncAllCompaniesMailchimpUsers />
                        </Card>
                    ) : null}
                </Grid>
            </Flex>
        </Container>
    );
};

export default FavoriteActions;
