/** @jsxImportSource theme-ui */
import { Badge, Link, Flex } from 'theme-ui';
import { Table } from '@component-controls/components';
import { useGetIncentivesQuery } from '@/store/apiService';
import { IncentiveActionType } from '@/types/types';
import { PencilIcon } from '@heroicons/react/solid';

type Props = {};

const tableColumns = [
    {
        columnKey: 'Id',
        Header: 'Id',
        accessor: 'Id',
    },
    {
        columnKey: 'Heading',
        Header: 'Heading',
        accessor: 'Heading',
    },
    {
        columnKey: 'ActionType',
        Cell: ({ cell }) => {
            const value: IncentiveActionType = cell.value;

            const actionType =
                value === IncentiveActionType.AddOn
                    ? 'Addon'
                    : value === IncentiveActionType.Information
                    ? 'Information'
                    : value === IncentiveActionType.Upgrade
                    ? 'Upgrade'
                    : value;
            return <Badge>{actionType}</Badge>;
        },
        Header: 'Action Type',
        accessor: 'ActionId',
    },
    {
        columnKey: 'InitialDelay',
        Header: 'Initial delay (seconds)',
        accessor: 'InitialDelayInSeconds',
    },
    {
        columnKey: 'InitialDeferredDays',
        Header: 'Delay after first login (days)',
        accessor: 'InitialDeferredDays',
    },
    {
        columnKey: 'ApplyToAllCompanies',
        Cell: ({ cell }) => {
            const value = cell.value;
            return <>{value === true ? 'Yes' : 'No'}</>;
        },
        Header: 'Apply to all companies',
        accessor: 'ApplyToAllCompanies',
    },
    {
        columnKey: 'Actions',
        Cell: ({ cell }) => {
            const row = cell.row;

            return (
                <Flex sx={{ justifyContent: 'flex-end', flexGrow: 1 }}>
                    <Link variant="button" href={`/#/incentives/${row.original.Id}`} title="Edit">
                        <PencilIcon width={16} height={16} />
                    </Link>
                </Flex>
            );
        },
        accessor: 'Actions',
    },
];

const Incentives = (props: Props) => {
    const incentivesState = useGetIncentivesQuery({});
    const incentives = incentivesState.data?.Results ? incentivesState.data.Results : [];

    return (
        <Flex>
            {incentives.length > 0 ? (
                <Table header={true} sorting={true} columns={tableColumns} data={incentives} />
            ) : null}
        </Flex>
    );
};

export default Incentives;
