export interface ServerError {
    FieldName: string;
    Message: string;
}

export const getErrorMessage = (error: any) => {
    let errorMessage = '';

    const responseData = error?.response ? error.response.data : null;

    if (responseData && responseData.ResponseStatus && responseData.ResponseStatus.Message) {
        errorMessage = responseData.ResponseStatus.Message;
    } else if (error._error) {
        errorMessage = error._error;
    } else if (error.error) {
        errorMessage = error.error;
    } else if (error.message) {
        errorMessage = error.message;
    }

    if (responseData?.ResponseStatus?.Errors && responseData?.ResponseStatus?.Errors.length > 1) {
        responseData.ResponseStatus.Errors.forEach((error: ServerError) => {
            errorMessage = error.Message;
        });
    }

    return errorMessage;
};

export const getInitials = (name?: string) => {
    if (!name) return;

    return name
        .split(' ')
        .map((str) => str.toUpperCase().slice(0, 1))
        .join('');
};

export const isValidUrl = (url: string) => {
    try {
        const _url = new URL(url).href;
        return true;
    } catch (error) {
        console.warn(error);
        return false;
    }
};
