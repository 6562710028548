import { useLocation } from 'react-router-dom';
import { Container, Heading, Box } from 'theme-ui';
import CreateApiKey from '@/containers/createApiKey';

const Dashboard = () => {
    const { pathname } = useLocation();
    return (
        <Container sx={{ flexBasis: '80%', flexGrow: 1, width: '100%' }}>
            <Box bg="background" p="3" sx={{ borderRadius: 'sm' }}>
                <Heading sx={{ textTransform: 'capitalize' }} as="h2">
                    Create API key
                </Heading>
                <CreateApiKey />
            </Box>
        </Container>
    );
};

export default Dashboard;
