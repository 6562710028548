/** @jsxImportSource theme-ui */
import { Flex, get, Theme, Heading, Box, Divider, Spinner, Grid, Container, Text } from 'theme-ui';
import { InformationCircleIcon } from '@heroicons/react/solid';
// import { Message, Icon, Button, Segment } from 'semantic-ui-react';

interface Error {
    ErrorCode: string;
    FieldName: string;
    Message: string;
}
type Props = {
    showReload?: boolean;
    error: {
        data: {
            ResponseStatus?: {
                ErrorCode: string;
                Errors: Error[];
                Message: string;
            };
        };
    };
    attached?: boolean;
    text?: string;
};

const defaultProps = {
    showReload: false,
};

const MessageList: React.FC<{ items: string[] }> = ({ items }) => {
    return (
        <ul>
            {items.map((item) => {
                return <li>{item}</li>;
            })}
        </ul>
    );
};

const ErrorMessage: React.FC<Props> = (props) => {
    const { error, text, showReload, attached } = props;

    return (
        <Container variant="message">
            <Box>
                <Heading color="danger">
                    {error?.data?.ResponseStatus?.Message
                        ? error?.data?.ResponseStatus.Message
                        : null}
                </Heading>
                {text}
                {!error ? `Error loading data` : null}

                {error && error?.data?.ResponseStatus?.Errors && (
                    <MessageList
                        items={error.data.ResponseStatus.Errors.map((error) => error.Message)}
                    />
                )}

                {/* @ts-ignore */}
                {error && error.error ? <Text color="danger">{error.error}</Text> : null}
            </Box>
        </Container>
    );
};

export default ErrorMessage;
