import { Flex, Box, Heading, Textarea, Select, Card, Spinner, Text, Badge } from 'theme-ui';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { TrashIcon } from '@heroicons/react/outline';
import { format } from 'date-fns';
import { Company } from '@/containers/companyDetails/types';
import Error from '@/components/Error';
import {
    useDeleteCompanyCommentMutation,
    useGetCommentTypesQuery,
    useGetCompanyCommentsQuery,
    usePostCompanyCommentMutation,
} from '@/store/apiService';
import { GrayFormikField } from '@/components/GrayFormikField';
import { useAppDispatch } from '@/store';
import Button from '@/components/Button';

const commentTypeColorMap = new Map([
    [2, 'orange.4'],
    [5, 'red.5'],
]);

export const CompanyComments = (company: Company) => {
    const dispatch = useAppDispatch();
    const { id } = useParams();

    const companyComments = useGetCompanyCommentsQuery({ companyId: id });
    const commentTypes = useGetCommentTypesQuery();
    const [createComment, createdCommentState] = usePostCompanyCommentMutation();
    const [deleteComment, deleteCommentState] = useDeleteCompanyCommentMutation();

    if (commentTypes.error) {
        return (
            <>
                <Heading>Add comment</Heading>
                <Text>Following error occured:</Text>
                <Error error={commentTypes.error as any} />
            </>
        );
    }

    if (companyComments.error) {
        return (
            <>
                <Heading>Add comment</Heading>
                <Text>Following error occured:</Text>
                <Error error={companyComments.error as any} />
            </>
        );
    }

    if (!commentTypes.data || !companyComments.data) {
        return <Spinner size={16} />;
    }

    return (
        <>
            <Flex sx={{ gap: 2, flexDirection: 'column' }}>
                <Box variant="segment">
                    <Heading>Add comment</Heading>
                    <Formik
                        initialValues={{
                            Id: '',
                            Comment: '',
                            CommentType: '1',
                        }}
                        onSubmit={(values, { resetForm, setErrors }) => {
                            createComment({
                                CompanyId: id,
                                ...values,
                                Comment: !values.Comment ? '-' : values.Comment,
                            })
                                .unwrap()
                                .then(() => {
                                    resetForm();
                                    companyComments.refetch();
                                })
                                .catch((err) => {
                                    resetForm();
                                    toast.error(err.data, { autoClose: 5000 });
                                });
                        }}
                    >
                        {(props) => {
                            return (
                                <Card variant="widget">
                                    <Flex
                                        sx={{
                                            gap: 2,
                                            flexDirection: 'column',
                                            alignItems: 'flex-end',
                                        }}
                                    >
                                        <GrayFormikField
                                            name="Comment"
                                            type="textarea"
                                            label="Comment"
                                        />

                                        <GrayFormikField
                                            label="Comment Type"
                                            type="select"
                                            name="CommentType"
                                            options={commentTypes.data.map((commentType) => ({
                                                value: commentType.Id,
                                                label: commentType.Name,
                                            }))}
                                        />

                                        <Flex sx={{ width: '100%', gap: 2 }}>
                                            <Button
                                                backgroundColor={commentTypeColorMap.get(5)}
                                                onClick={() => {
                                                    props.setFieldValue(
                                                        'Comment',
                                                        'Not interested'
                                                    );
                                                    props.setFieldValue('CommentType', 5);
                                                    props.submitForm();
                                                }}
                                            >
                                                Not interested
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    props.setFieldValue(
                                                        'Comment',
                                                        'Call without an answer'
                                                    );
                                                    props.setFieldValue('CommentType', 2);
                                                    props.submitForm();
                                                }}
                                                backgroundColor={commentTypeColorMap.get(2)}
                                                mr="auto"
                                            >
                                                Call without answer
                                            </Button>
                                            <Button
                                                sx={{ marginLeft: 'auto' }}
                                                isLoading={props.isSubmitting}
                                                disabled={props.isSubmitting}
                                                type="submit"
                                                onClick={props.submitForm}
                                            >
                                                Save
                                            </Button>
                                        </Flex>
                                    </Flex>
                                </Card>
                            );
                        }}
                    </Formik>
                </Box>
                <Box variant="segment">
                    <Heading>Comments</Heading>
                    {companyComments.data ? (
                        <Flex sx={{ gap: 2, flexDirection: 'column' }}>
                            {companyComments.data.length === 0 ? (
                                <Text>No comments to show</Text>
                            ) : null}
                            {companyComments.data.map((comment) => {
                                return (
                                    <Card variant="widget">
                                        <Flex sx={{ alignItems: 'center', mb: 2 }}>
                                            <Text sx={{ flexGrow: 1 }}>
                                                {format(new Date(comment.Created), 'd MMM yyyy, p')}
                                            </Text>
                                            {/* @ts-ignore */}
                                            <Text>{comment.CreatedBy}</Text>
                                            <Badge
                                                backgroundColor={
                                                    commentTypeColorMap.get(
                                                        commentTypes.data.find(
                                                            (ct) => ct.Name === comment.CommentType
                                                        )?.Id
                                                    )
                                                        ? commentTypeColorMap.get(
                                                              commentTypes.data.find(
                                                                  (ct) =>
                                                                      ct.Name ===
                                                                      comment.CommentType
                                                              )?.Id
                                                          )
                                                        : 'blue.3'
                                                }
                                            >
                                                {
                                                    commentTypes.data.find(
                                                        (ct) => ct.Name === comment.CommentType
                                                    )?.Name
                                                }
                                            </Badge>
                                        </Flex>
                                        <Flex sx={{ alignItems: 'center' }}>
                                            <Flex sx={{ gap: 2, flexGrow: 1 }}>
                                                <Text>{comment.Comment}</Text>
                                            </Flex>
                                            <Button
                                                isLoading={
                                                    deleteCommentState?.originalArgs?.commentId ===
                                                        comment.Id && deleteCommentState.isLoading
                                                }
                                                variant="dangerOutline"
                                                onClick={() => {
                                                    deleteComment({
                                                        companyId: id,
                                                        commentId: comment.Id,
                                                    })
                                                        .unwrap()
                                                        .catch((err) => {
                                                            toast.error(err.message, {
                                                                autoClose: 5000,
                                                            });
                                                        });
                                                }}
                                            >
                                                <TrashIcon width={16} height={16} color="danger" />
                                            </Button>
                                        </Flex>
                                    </Card>
                                );
                            })}
                            {companyComments.isFetching ? <Spinner size={16} /> : null}
                        </Flex>
                    ) : null}
                </Box>
            </Flex>
        </>
    );
};
