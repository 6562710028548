import {
    HomeIcon,
    OfficeBuildingIcon,
    UsersIcon,
    CalendarIcon,
    TicketIcon,
    CashIcon,
    BellIcon,
} from '@heroicons/react/outline';
import {
    HomeIcon as HomeIconSolid,
    OfficeBuildingIcon as OfficeBuildingIconSolid,
    UsersIcon as UsersIconSolid,
    CalendarIcon as CalendarIconSolid,
    TicketIcon as TicketIconSolid,
    CashIcon as CashIconSolid,
    BellIcon as BellIconSolid,
} from '@heroicons/react/solid';

// TODO: combine CustomLink and getIconForRoute in one component

export function getIconForRoute(route: string) {
    switch (route) {
        case '/':
            return [HomeIcon, HomeIconSolid];

        case '/companies':
            return [OfficeBuildingIcon, OfficeBuildingIconSolid];

        case '/users':
            return [UsersIcon, UsersIconSolid];

        case '/bookings':
            return [CalendarIcon, CalendarIconSolid];

        case '/tickets':
            return [TicketIcon, TicketIconSolid];

        case '/invoicing':
            return [CashIcon, CashIconSolid];

        case '/incentives':
            return [BellIcon, BellIconSolid];

        default:
            return [];
    }
}
