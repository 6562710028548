import { Box, Grid, Field, Heading, Card } from 'theme-ui';
import { Company } from './types';
import { Formik } from 'formik';
import { Map, GOOGLE_MAP_URL } from './CompanyInfo';
import { GrayFormikField } from '../../components/GrayFormikField';

export const CompanyInfoPartial = (company: Company) => {
    return (
        <Formik
            initialValues={{
                ...company,
            }}
            onSubmit={(values) => {
                console.log(values);
            }}
        >
            {(props) => (
                <Box variant="segment" sx={{ overflow: 'auto' }}>
                    <Heading>General</Heading>
                    <Card variant="widget">
                        <Grid variant="column">
                            <Box variant="segment">
                                <GrayFormikField
                                    name="OrganisationNumber"
                                    type="text"
                                    label="Organisation number"
                                />
                                <GrayFormikField name="Name" type="text" label="Name" />
                                <GrayFormikField name="Phone" type="text" label="Phone" />
                                <GrayFormikField name="Email" type="email" label="Email" />
                                <GrayFormikField name="TypeId" type="text" label="Type" />
                                <GrayFormikField name="CategoryId" type="text" label="Category" />
                                <GrayFormikField
                                    name="SitePath"
                                    type="text"
                                    label="Booking homepage"
                                    prefix="https://"
                                />
                                <GrayFormikField name="Homepage" type="text" label="Homepage" />
                                <GrayFormikField name="Details" type="textarea" label="Details" />
                            </Box>
                        </Grid>
                        <Grid variant="column">
                            <Heading>Address</Heading>
                            {process.env.NODE_ENV !== 'development' &&
                            company.Latitude &&
                            company.Longitude ? (
                                <Map
                                    // @ts-ignore
                                    googleMapURL={GOOGLE_MAP_URL}
                                    // @ts-ignore
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    // @ts-ignore
                                    containerElement={<div style={{ height: `360px` }} />}
                                    // @ts-ignore
                                    mapElement={<div style={{ height: `100%` }} />}
                                    // @ts-ignore
                                    lat={parseFloat(company.Latitude)}
                                    // @ts-ignore
                                    lng={parseFloat(company.Longitude)}
                                />
                            ) : null}
                            <Box variant="segment">
                                <GrayFormikField name="Street1" type="text" label="Street 1" />
                                <GrayFormikField name="Street2" type="text" label="Street 2" />
                                <GrayFormikField name="ZipCode" type="text" label="Zip Code" />
                                <GrayFormikField name="City" type="text" label="City" />
                                {/* <GrayFormikField
                                    name="CountryId"
                                    type="select"
                                    label="Country"
                                /> */}
                                <Box variant="group">
                                    <GrayFormikField
                                        name="Latitude"
                                        type="number"
                                        label="Latitude"
                                        step="any"
                                    />
                                    <GrayFormikField
                                        name="Longitude"
                                        type="number"
                                        label="Longitude"
                                        step="any"
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Card>
                </Box>
            )}
        </Formik>
    );
};
