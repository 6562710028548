/** @jsxImportSource theme-ui */
import { useAuthentication } from '@/providers/AuthenticationProvider';
import { useActor } from '@xstate/react';
import { Routes, Route } from 'react-router-dom';
import Companies from '../containers/companies/Companies';
import Bookings from '../containers/bookings/Bookings';
import SupportCases from '../containers/supportCases/SupportCases';
import CompanyDetails from '../containers/companyDetails/CompanyDetails';
import ApiKeys from './ApiKeys';
import { getIsAuthenticated } from '@/machines/authMachine';
import ProtectedRoute from '@/components/ProtectedRoute';
import FavoriteActions from '@/containers/favoriteActions/FavoriteActions';
import CreateIncentivePage from './CreateIncentivePage';
import EditIncentivePage from './EditIncentivePage';
import Incentives from './IncentivesPage';

const Full: React.FC = () => {
    // @ts-ignore
    const { authService } = useAuthentication();
    const authActor = useActor(authService as any);
    const authState: any = authActor[0];
    const isAuthenticated = getIsAuthenticated(authState);

    return (
        <>
            <Routes>
                <Route
                    key="home"
                    path="/"
                    element={
                        <ProtectedRoute authenticated={isAuthenticated}>
                            <FavoriteActions />
                        </ProtectedRoute>
                    }
                />
                <Route
                    key="/incentives"
                    path="/incentives"
                    element={
                        <ProtectedRoute authenticated={isAuthenticated}>
                            <Incentives />
                        </ProtectedRoute>
                    }
                />
                <Route
                    key="/clients/:companyId/incentives/:incentiveId"
                    path="/clients/:companyId/incentives/:incentiveId"
                    element={
                        <ProtectedRoute authenticated={isAuthenticated}>
                            <CreateIncentivePage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    key="/incentives/new"
                    path="/incentives/new"
                    element={
                        <ProtectedRoute authenticated={isAuthenticated}>
                            <CreateIncentivePage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    key="/incentives/:incentiveId"
                    path="/incentives/:incentiveId"
                    element={
                        <ProtectedRoute authenticated={isAuthenticated}>
                            <EditIncentivePage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    key="/clients"
                    path="/clients"
                    element={
                        <ProtectedRoute authenticated={isAuthenticated}>
                            <Companies />
                        </ProtectedRoute>
                    }
                />
                <Route
                    key="/clients/:id"
                    path="/clients/:id"
                    element={
                        <ProtectedRoute authenticated={isAuthenticated}>
                            <CompanyDetails />
                        </ProtectedRoute>
                    }
                />
                <Route
                    key="/bookings"
                    path="/bookings"
                    element={
                        <ProtectedRoute authenticated={isAuthenticated}>
                            <Bookings />
                        </ProtectedRoute>
                    }
                />
                <Route
                    key="/tickets"
                    path="/tickets"
                    element={
                        <ProtectedRoute authenticated={isAuthenticated}>
                            <SupportCases />
                        </ProtectedRoute>
                    }
                />
                <Route
                    key="/apikeys"
                    path="/apikeys/:id"
                    element={
                        <ProtectedRoute authenticated={isAuthenticated}>
                            <ApiKeys />
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </>
    );
};

export default Full;
